import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/api-service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../services/common/common.service';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';



@Component({
  selector: 'app-nfc-tag',
  templateUrl: './nfc-tag.component.html',
  styleUrls: ['./nfc-tag.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],

})
export class NfcTagComponent implements OnInit {

  isnoData = false;
  nfcData: any;
  private modalRef: any;
  qr_image = environment.qr_images;
  slug_images = environment.slug_images
  @ViewChild('mymodal', { static: false }) qrModal: ElementRef;
  qrimg: string;
  seqdata: any
  time: any;
  encodedId: any;
  userData: any;
  title: any;
  services: any;
  lost_found: any;
  baseUrl = environment.baseURL;
  location: Location;


  constructor(private modalService: NgbModal, private auth: ApiService, private router: Router, private route: ActivatedRoute, private common: CommonService,location: Location) {
    this.common.showSpinner();
    this.location = location;
    this.route.params.subscribe((res: any) => {
      (!res)
        ? this.router.navigate(['/'])
        : this.getNfcTagInfo(res);
    })
  }

  ngOnInit(): void {
  }

   /* === NFC Tag (mobile view open to app) === */
  getNfcTagInfo(res: any) {
    let data = { 'tag_id': res.id };
    this.scanCount(res)
    this.auth.getNfcInfo(data).subscribe((response: any) => {
      if (response['success'] == 1) {
        this.nfcData = response
        this.userData = response.user

        if(response.user.instant == 1){
          this.userData =response.user
          if (response.user.instant_slug != "" || response.user.instant_slug || response.user.instant_slug != undefined) {
            if (response.user.instant_slug == 'event') {
              var result = response.services.filter((o1: any) => {
                if (o1.slug == 'event') {
                  this.encodedId = o1.id;
                }
              })
              const encodedIdd = btoa(this.encodedId);
              const data: any = this.common.redirectToUrl(encodedIdd, this.userData.instant_slug, '');
              if (data) {
                this.common.hideSpinner()
                this.common.success('File downloaded successfully');
                window.location.href = data.url;
              } else {
                Swal.fire('Oops...', 'Somthing went wrong !', 'error');
                this.common.hideSpinner();
              }
              //  debugger
            } else if (response.user.instant_slug == 'contact') {
              this.common.hideSpinner()
              this.addContact(this.encodedId)
            }
            else if (response.user.instant_slug == 'messages') {
              this.common.hideSpinner()
              var result = response.services.filter((o1: any) => {
                if (o1.slug == 'messages') {
                  this.title = o1.title;
                }
              })
              const { url }: any = this.common.redirectToUrl(this.userData.instant_content, this.userData.instant_slug, this.title);
              location.replace(url);
            }
            else if (response.user.instant_slug == 'medical') {
              let { file_name } = this.services.find((e: any) => e.slug == response.user.instant_slug);
              this.qrimg = this.lost_found + file_name;
              this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
              return;
            }
            else if (response.user.instant_slug == 'notes') {
              this.common.hideSpinner()
              const { url }: any = this.common.redirectToUrl(this.userData.instant_content, this.userData.instant_slug, '');
              location.replace(url);
            }


            else if (response.user.instant_slug == 'wifi' || response.user.instant_slug == 'pcr' || response.user.instant_slug == 'vaccine') {
              this.common.hideSpinner()
              let { qrimage } = this.services.find((e: any) => e.slug == response.user.instant_slug);
              this.qrimg = this.qr_image + qrimage;
              this.modalRef = this.modalService.open(this.qrModal, {
                size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'
              });
              return;

            } else {

              this.common.hideSpinner()

              if (this.userData.instant_content && this.userData.instant_slug) {
                if (this.userData.instant_type == 'quickfiles' || this.userData.instant_type == 'quick_access') {
                    if (this.userData.instant_slug == 'file') {

                    let urll =  environment.slug_images + this.userData.instant_file_name
                    location.replace(urll)

                  }
                    else if(this.userData.instant_slug == 'bapp'){
 
                  let split = this.userData.instant_bapp.split("|")
                    if(split[0] == "lost_found" || split[0]  == "emenu" || split[0]  == "linktree" || split[0]  == "emergency" || split[0]  == "contact" || split[0]  == "event" || split[0]  == "calendar" || split[0]  == "notes"){
                     location.replace(this.userData.instant_content);
                    }else{
                      const { url }: any = this.common.redirectToUrl(this.userData.instant_content, this.userData.instant_slug, '');
                      location.replace(url);
                    }
                  }
                  else {
                    location.replace(this.userData.instant_content)
                  }
                } else {
                  const { url}: any = this.common.redirectToUrl(this.userData.instant_content, this.userData.instant_slug, '');
                  location.replace(url);
                }
              } else {
                this.common.error('No data found');
              }
            }
            this.saveOnLinkClick();
          } else {
            this.common.hideSpinner()
            Swal.fire('Oops...', 'No link/application found', 'error');
          }
        } 
        else{
        if (response.item) {
          if (response.item.data.length == 0) {
            this.isnoData = true;
            this.common.error('No Data Found');
            this.common.hideSpinner();
            return
          } else {
          
            

            if ((response.item.slug == 'lost_found') || (response.item.slug == 'emergency') || (response.item.slug == 'linktree') || (response.item.slug == 'emenu') || (response.item.slug == 'contact')) {
    
              const data: any = this.common.redirectToUrl(response.item.data, response.item.slug, '');
              if (data) {
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            } else if (response.item.slug == 'event') {
              const encodedId = btoa(response.item.id);
              const data: any = this.common.redirectToUrl(encodedId, response.item.slug, '');
              if (data) {
                this.common.success('File downloaded successfully');
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            }
    
            else if (response.item.slug == 'phone') {
              const data: any = this.common.redirectToUrl(this.nfcData.item.title, this.nfcData.item.slug, this.nfcData.item.data.content);
    
            }

            else if (response.item.slug == 'wifi') {
              this.common.hideSpinner();
              this.qrimg = this.qr_image + response.item.data.qrimage;
              this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
              return;
            }
            else if (response.item.slug == 'cv') {
              this.common.hideSpinner();
              const encodedId = btoa(response.item.id);
              let UrlData = this.nfcData.item.data.content ? this.nfcData.item.data.content : this.slug_images + this.nfcData.item.data.file_name
              const data: any = this.common.redirectToUrl(UrlData, this.nfcData.item.slug, '');
              if (data) {
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            }
            else if (response.item.slug == 'whatsApp') {
              this.common.hideSpinner();
              const encodedId = btoa(response.item.id);
              const data: any = this.common.redirectToUrl(this.nfcData.item.title, this.nfcData.item.slug, this.nfcData.item.data.content);
              if (data) {
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            }
            else if (response.item.slug == 'notes') {
              this.common.hideSpinner();
              const data: any = this.common.redirectToUrl(this.nfcData.item.data, this.nfcData.item.slug, this.nfcData.item.data);
              if (data) {
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            }
            else if (response.item.slug == 'sequencer') {
              let unix = moment().unix();
              let current =  moment().unix();
              let seq = response.item.data.services.split(',')
              this.time  = response.item.data.started_on == ""? unix:response.item.data.started_on
              if(response.item.data.started_on){
                let remaing = current -  this.time
                let hours:any = JSON.parse(response.item.data.reset_timer) * 60 * 60
                if(remaing < hours){
                  let nextItem
                  let index = seq.indexOf(response.item.data.current_service);
                  if (index >= 0 && index < seq.length - 1)
                    nextItem = seq[index + 1]
                  this.seqdata = nextItem
                  if(this.seqdata == undefined){
                    this.seqdata = seq[0]
                  }
                }else{
                 this.time = unix
                  this.seqdata = seq[0]
                }
              }else{
                this.seqdata = seq[0]
              }
              let seqsplit = this.seqdata.split('|')
              this.sequencer(seqsplit[0], seqsplit[1])
              this.updateSequecer(response, this.seqdata,  this.time)
            }
            else {
              let serviceData = { 'profile_user_id': this.userData.user_id, 'slug': response.item.data.slug, 'service_id': response.item.data.id };
              this.auth.saveUserServicesClicks(serviceData).subscribe((response: any) => {
                if (response.success == 1) {
                  const data: any = this.common.redirectToUrl(this.nfcData.item.data.content, this.nfcData.item.slug, this.nfcData.item.data.title);
                  if (data) {
                    window.location.href = data.url;
                  } else {
                    this.common.error('Somthing went wrong');
                    this.common.hideSpinner();
                  }
                }
              })
            }
          
        }
        } else {
         if(response.profile_id>0){
           if(this.location.path().includes('?lang=')){
            var selected_lang_split = this.location.path().split('?lang=')
            var selected_lang =  selected_lang_split[1]
            this.location.path().includes('?lead_form=1') ? this.router.navigate(['/profile', response.username,response.profile_id,'device'],{queryParams:{'lead_form':1,'lang':selected_lang},queryParamsHandling: 'merge'}) :  this.router.navigate(['/profile', response.username,response.profile_id,'device'],{queryParams:{'lang':selected_lang},queryParamsHandling: 'merge'})
            }
           
           else{
            if(this.userData?.language){
              this.location.path().includes('?lead_form=1') ? this.router.navigate(['/profile', response.username,response.profile_id,'device'],{queryParams:{'lead_form':1,'lang':this.userData?.language},queryParamsHandling: 'merge'}) :  this.router.navigate(['/profile', response.username,response.profile_id,'device'],{queryParams:{'lang':this.userData?.language},queryParamsHandling: 'merge'})

            }

            else{
              this.location.path().includes('?lead_form=1') ? this.router.navigate(['/profile', response.username,response.profile_id,'device'],{queryParams:{'lead_form':1},queryParamsHandling: 'merge'}) :  this.router.navigate(['/profile', response.username,response.profile_id,'device'])
             }
           }
          }
          else{

            if(this.location.path().includes('?lang=')){
              var selected_lang_split = this.location.path().split('?lang=')
              var selected_lang =  selected_lang_split[1]
              this.location.path().includes('?lead_form=1') ? this.router.navigate(['/profile', response.username,'device'],{queryParams:{'lead_form':1,'lang':selected_lang},queryParamsHandling: 'merge'}) :  this.router.navigate(['/profile', response.username,'device'],{queryParams:{'lang':selected_lang},queryParamsHandling: 'merge'})
            }
             
             else{
              if(this.userData?.language){
                this.location.path().includes('?lead_form=1') ? this.router.navigate(['/profile', response.username,'device'],{queryParams:{'lead_form':1,'lang':this.userData?.language},queryParamsHandling: 'merge'}) :  this.router.navigate(['/profile', response.username,'device'],{queryParams:{'lang':this.userData?.language},queryParamsHandling: 'merge'})
  
              }
  
              else{
               this.location.path().includes('?lead_form=1') ? this.router.navigate(['/profile', response.username,'device'],{queryParams:{'lead_form':1},queryParamsHandling: 'merge'}) : this.router.navigate(['/profile', response.username,'device'])
               }
             }
          }
        }
      }
        this.common.hideSpinner();
      }else if (response['status'] == 'fresh'){
        let url = 'https://b-in-touch.fr/welcome-page'
         location.replace(url)
      } 

     else if(response['success'] == 0){
        this.common.error(response.msg)
        this.common.hideSpinner();

      }
      
      else {

        this.isnoData = true;
        this.common.hideSpinner();

      }
    }, (e) => {
      
      this.common.hideSpinner();
    })
  }

  addContact(data: any) {
    if (this.userData.contact_id) {
      const encodedStringBtoA = btoa(this.userData.contact_id);
      window.location.href = `${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`;
      this.common.success('File Downloaded Successfully');

    }
  }



  scanCount(res: any) {   // calaculate the count of card scan
    let data = { 'tag_id': res.id };
    this.auth.totalScan(data).subscribe((response: any) => {

    })
  }

  deeplink(url: string, bundlename: string) {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check
    if (isIphone == false) {
      let app = {
        launchApp: function () {
          setTimeout(function () {
            window.open(url, '_blank')?.focus();
          }, 25);
          window.open(bundlename, '_blank')?.focus();

        },

        openWebApp: function () {
          window.open(url, '_blank')?.focus();
        }
      };
      app.launchApp();
    } else if (isAndroid == false) {
      let app = {
        launchApp: function () {
          window.open(bundlename, '_blank')?.focus();
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function () {
          window.open(url, '_blank')?.focus();
        }
      };
      app.launchApp();
    }
    else {
      window.open(url, '_blank')?.focus();
    }
  }

   /* === Functionality of Mobile view of sequncer  === */
  sequencer(id: any, slug: any) {
    const encodedId = btoa(id);
    let data = {
      id: encodedId,
      slug: slug, 
      full:1
    }
    this.auth.getQrservices(data).subscribe((response: any) => {
      this.common.hideSpinner();
      if (response['success'] == 1) {
        if( response.item.slug == 'whatsApp'){
        const data: any = this.common.redirectToUrl(response.item.data.content, response.item.slug, response.item.title);
        if (data) {
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      }   if ((response.item.slug == 'lost_found') || (response.item.slug == 'emergency') || (response.item.slug == 'linktree') || (response.item.slug == 'emenu') || (response.item.slug == 'contact')) {
        const data: any = this.common.redirectToUrl(response.item.data, response.item.slug, '');
        if (data) {
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      } else if (response.item.slug == 'event') {
        const encodedId = btoa(response.item.id);
        const data: any = this.common.redirectToUrl(encodedId, response.item.slug, '');
        if (data) {
          this.common.success('File downloaded successfully');
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      }
      else if (response.item.slug == 'phone') {
        const data: any = this.common.redirectToUrl(response.item.title, response.item.slug, response.item.data.content);
      }

      else if (response.item.slug == 'wifi') {
        this.common.hideSpinner();
        this.qrimg = this.qr_image + response.item.data.qrimage;
        this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
        return;
      }
      else if (response.item.slug == 'cv') {
        this.common.hideSpinner();
        const encodedId = btoa(response.item.id);
        let UrlData = response.item.data.content ? response.item.data.content : this.slug_images + response.item.data.file_name
        const data: any = this.common.redirectToUrl(UrlData, response.item.slug, '');
        if (data) {
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      }else {
        const data: any = this.common.redirectToUrl(response.item.data.content, response.item.slug, response.item.title);
        if (data) {
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      }
    }
  })
  }

  saveOnLinkClick() {
    const slugData = this.services.find((e: any) => {
      return e.slug === this.userData.instant_slug
    });
    if (slugData) {
      let data = { 'profile_user_id': this.userData.user_id, 'slug': this.userData.instant_slug, 'service_id': slugData.id };
      this.auth.saveUserServicesClicks(data).subscribe((response: any) => {
        if (response.success == 1) {

        }
      })
    }
  }

   /* === Update sequncer time and App  === */
  updateSequecer(res: any, current: any, time:any) {
    var data = new FormData
    data.append('id', res.item.data.id)
    data.append('reset_timer', res.item.data.reset_timer)
    data.append('title', res.item.data.title)
    data.append('services', res.item.data.services)
    data.append('current_service', current)
    data.append('started_on', time)
    data.append('user_id', res.item.data.user_id)
    this.auth.AppSequencer(data).subscribe((res: any) => {
      if (res.success == 1) {
      }
    })
  }

}
