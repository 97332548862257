import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//version update change
import { RouterModule } from '@angular/router';
// ***************** Angular Firebase Module ***********************************
import { AngularFireModule } from '@angular/fire/compat';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging'
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {MessagingService} from './services/message/messaging.service';
import { EventsService } from './services/event/events.service';
import { LanguageInterceptor } from './services/interceptor/language.interceptor';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        CommonModule,
        BrowserAnimationsModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        ToastrModule.forRoot({
            maxOpened: 1, // Set the maximum number of toasts to be shown at once
            autoDismiss: true // Automatically dismiss older toasts
        })], providers: [MessagingService, EventsService, TranslateService, { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true }, provideHttpClient(withInterceptorsFromDi()),] })
export class AppModule {}
