import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "../api-service/api.service";
import { Platform } from '@angular/cdk/platform';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { coerceStringArray } from '@angular/cdk/coercion';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';


declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  dropdownSettings: any;
  addServiceDropSetting: any;
  countryCodeList: Array<any> = [];
  token = 'token';
  lat: any;
  long: any;
  key = 'XThZpPIzIIne69KIjpnF_Tg9mpdoakmw'
  slug = 'slug_data';
  current_address: any = '';
  testBrowser: boolean;
  wazeData: string;
  chromeData: string;
  destination_latitude: any = '';
  destination_longitude: any = '';
  current_location_latitude: number;
  current_location_longitude: number;
  destination_addressvar: string;

  constructor(
    private toaster: ToastrService,
    private handler: HttpBackend, private http: HttpClient,
    private api: ApiService,
    private spinner: NgxSpinnerService,
    private platform: Platform,
  ) {

    this.dropdownSettings = {
      enableCheckAll: false,
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };
    this.addServiceDropSetting = this.dropdownSettings;
    this.addServiceDropSetting.singleSelection = true;
  }

  success(message: string): void {
    this.toaster.success(message, '');
  }
  error(message: string): void {
    
    this.toaster.error(message, ''
    );
  }

  getToken(): any {
    try {
      return localStorage.getItem(this.token);
    } catch (e) { }
    return null;
  }

    /* === NFC redireation=== */
//   redirectToUrl(data: any, slug: string, title: string) {
//     switch (slug) {
//       case 'instagram': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `https://www.instagram.com/${data}`;
//           var bundlename: any = `instagram://user?username=${data}`;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `https://www.instagram.com/${data}`
//           var bundlename: any = `https://www.instagram.com/${data}`;
//         }
//         else {

//           var url: any = `https://www.instagram.com/${data}`
//           var bundlename: any = `https://www.instagram.com/${data}`;
//         }
//         let dataUrl:any = { url, bundlename };
//         return dataUrl;
//       }
//       case 'tiktok': {
//         const url = `https://www.tiktok.com/@${data}`
//         const bundlename: any = `https://www.tiktok.com/@${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
//       case 'snapchat': {
//         const url = `https://www.snapchat.com/add/${data}`
//         const bundlename: any = `https://www.snapchat.com/add/${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
//       case 'skype': {
//         if (this.platform.isBrowser && this.platform.ANDROID) {
//           var url: any = `https://www.skype.com/en`
//           var bundlename: any = `skype:${data}?chat`;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `https://www.skype.com/en`
//           var bundlename: any = `skype://`;
//         }
//         else {
//           var url: any = `https://www.skype.com/en`
//           var bundlename: any = `https://www.skype.com/en`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'meetup': {
//         if (this.platform.isBrowser && this.platform.ANDROID) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
//       case 'behance': {
//         var mydata = data;
//         if (!mydata.includes('https://')) {
//           var pathname = mydata.split('https://')[1];
//           var url: any = "https://www.behance.net/" + pathname;
//           var bundlename: any = "https://www.behance.net/" + pathname;
//         }
//         else {
//           var url: any = mydata;
//           var bundlename: any = mydata;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'facebook': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var a = data
//           if (a.includes('id')) {
//             var userid = a.split("id=")[1]
//             var url: any = `${data}`
//             var bundlename: any = `fb://profile/${userid}`;
//           }
//           else {
//             var url: any = `${data}`
//             var bundlename: any = `fb://facewebmodal/f?href=${data}`;
//           }
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var a = data
//           if (a.includes('id')) {
//             var userid = a.split("id=")[1]
//             var url: any = `${data}`
//             var bundlename: any = `fb://profile/${userid}`;
//           }
//           else {
//             var url: any = `${data}`
//             var bundlename: any = `fb://facewebmodal/f?href=${data}`;
//           }
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'twitter': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `https://twitter.com/${data}`;
//           var bundlename: any = `twitter://user?screen_name=${data}`;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `https://twitter.com/${data}`;
//           var bundlename: any = `https://twitter.com/${data}`;
//         }
//         else {
//           var url: any = `https://twitter.com/${data}`
//           var bundlename: any = `https://twitter.com/${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'youtube': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var mydata = data
//           if (mydata.includes('watch?v=')) {
//             var pathname = mydata.split('watch?v=')[1];
//           }
//           else {
//             var pathname = mydata.split('youtu.be/')[1];
//           }
//           var url: any = `${data}`
//           var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;
//         }
//         else if (this.platform.IOS) {
//           var mydata = data
//           if (mydata.includes('watch?v=')) {
//             var pathname = mydata.split('watch?v=')[1];
//           }
//           else {
//             var pathname = mydata.split('youtu.be/')[1];
//           }
//           var url: any = `${data}`
//           var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;
//         }
//         else {
//           var mydata = data
//           if (mydata.includes('watch?v=')) {
//             var pathname = mydata.split('watch?v=')[1];
//           }
//           else {
//             var pathname = mydata.split('youtu.be/')[1];
//           }
//           var url: any = `${data}`
//           var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'youtubeMusic': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       // case 'linkedin': {
//       //   if (this.platform.ANDROID && this.platform.isBrowser) {
//       //     var url: any = `${data}`
//       //     var mydata = data
//       //     if (mydata.includes('https:')) {
//       //       var pathname = mydata.split('linkedin.com/in/')[1];
//       //       console.log(pathname)
//       //       var bundlename: any = "https://www.linkedin.com/in/" + pathname;
//       //     }
//       //     else {
//       //       console.log(pathname)
//       //       var bundlename: any = "linkedin://" + pathname;
//       //     }
//       //   }
//       //   else if (this.platform.IOS && this.platform.SAFARI) {
//       //     var url: any = `${data}`
//       //     var mydata = data
//       //     if (mydata.includes('https:')) {
//       //       var pathname = mydata.split('linkedin.com/in/')[1];
//       //       var bundlename: any = "https://www.linkedin.com/in/" + pathname;
//       //     }
//       //     else {
//       //       var bundlename: any = "linkedin://" + pathname;
//       //     }
//       //   }
//       //   else {
//       //     var url: any = `${data}`
//       //     var bundlename: any = `${data}`;
//       //   }
//       //   let dataUrl = { url, bundlename };
//       //   return dataUrl;
//       // }

//       case 'linkedin': {
//         var url: any = `${data}`
//         var bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }


//       case 'devianArt': {
//         debugger;
//         if (this.platform.ANDROID && this.platform.isBrowser) {

//           if(!(`${data}`).includes('https://')){
//             var url: any = `https://www.deviantart.com/${data}`
//             var bundlename: any = `DeviantArt://profile/${data}`; 

//         }
//         else{
//           var url: any = `${data}`
//           var bundlename: any = `DeviantArt://profile/${data}`;  
//         }
//        }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           if(!(`${data}`).includes('https://')){
//             var url: any = `https://www.deviantart.com/${data}`
//             var bundlename: any = `DeviantArt://profile/${data}`; 

//         }
//         else{
//           var url: any = `${data}`
//           var bundlename: any = `DeviantArt://profile/${data}`;  
//         }
//         }
//         else {
//           if(!(`${data}`).includes('https://')){
//             var url: any = `https://www.deviantart.com/${data}`
//             var bundlename: any = `https://www.deviantart.com/${data}`; 

//         }
//         else{
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;  
//         }
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'pinterest': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `https://www.pinterest.com/${data}`
//           var bundlename: any = `https://www.pinterest.com/${data}`;
//         }
//         else if (this.platform.IOS) {
//           var url: any = `https://www.pinterest.com/${data}`
//           var bundlename: any = `https://www.pinterest.com/${data}`;
//         }
//         else {
//           var url: any = `https://www.pinterest.com/${data}`
//           var bundlename: any = `https://www.pinterest.com/${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'reddit': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var bundlename: any = `reddit://${data}`;
//         }
//         else if (this.platform.IOS) {
//           var url: any = `${data}`
//           var bundlename: any = `reddit://${data}`;
//         }
//         else {
//           var url: any = `https://www.reddit.com/user/${data}`
//           var bundlename: any = `https://www.reddit.com/user/${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'zoom': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'viber': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `viber://chat?number=${data}`
//           var bundlename: any = `viber://chat?number=${data}`;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `https://account.viber.com/`;
//           var bundlename: any = `viber://chat?number=${data}`;
//         }
//         else {
//           var url: any = `https://account.viber.com/contact?number=${data}`
//           var bundlename: any = `https://account.viber.com/contact?number=${data}`
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'microsoftTeams': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var mydata = data
//           if (mydata.includes('https:')) {
//             var teampath = mydata.split('meet/')[1];
//           }
//           var url: any = `${data}`
//           var bundlename: any = "https://teams.live.com/meet/" + teampath;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var mydata = data
//           if (mydata.includes('https:')) {
//             var pathname = mydata.split('meet/')[1];
//           }
//           var url: any = `${data}`
//           var bundlename: any = "https://teams.live.com/meet/" + pathname;
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'drive': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var mydata = data
//           if (mydata.includes('https')) {
//             var pathname = mydata.split('google.com/')[1];
//           }
//           var url: any = `${data}`
//           var bundlename: any = " https://drive.google.com/" + pathname;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var mydata = data
//           if (mydata.includes('https')) {
//             var pathname = mydata.split('google.com/')[1];
//           }
//           var url: any = `${data}`
//           var bundlename: any = " https://drive.google.com/" + pathname;
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl

//       }

//       case 'qq': {

//         const url = `${data}`
//         const bundlename: any = `${data}`;


//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'kik': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           if(!(`${data}`).includes('https')){
//             var url: any = `https://kik.me/${data}`
//             var bundlename: any = `https://kik.me/${data}`;
//           }
//           else{
//             var url: any = `${data}`
//             var bundlename: any = `${data}`;
//           }
        
//         }
//         else if (this.platform.IOS) {
//           if(!(`${data}`).includes('https')){
//             var url: any = `https://kik.me/${data}`
//             var bundlename: any = `https://kik.me/${data}`;
//           }
//           else{
//             var url: any = `${data}`
//             var bundlename: any = `${data}`;
//           }
//         }
//         else {
//           if(!(`${data}`).includes('https')){
//             var url: any = `https://kik.me/${data}`
//             var bundlename: any = `https://kik.me/${data}`;
//           }
//           else{
//             var url: any = `${data}`
//             var bundlename: any = `${data}`;
//           }
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl
//       }

//       case 'tumblr': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var mydata = data
//           if (mydata.includes('https')) {
//             var pathname = mydata.split('https://')[1];
//           }
//           else {
//             var pathname = mydata.split('http://')[1];
//           }
//           var pathname1 = pathname.split('.tumblr.com')[0];
//           var url: any = `${data}`
//           var bundlename: any = "https://" + pathname1 + ".tumblr.com";
//         }

//         else if (this.platform.IOS) {
//           var mydata = data
//           if (mydata.includes('https')) {
//             var pathname = mydata.split('https://')[1];
//           }
//           else {
//             var pathname = mydata.split('http://')[1];

//           }
//           var pathname1 = pathname.split('.tumblr.com')[0];
//           var url: any = `${data}`
//           var bundlename: any = "tumblr://x-callback-url/blog?blogName=" + pathname1;
//         }

//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'tinder': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else if (this.platform.IOS) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else if (this.platform.SAFARI) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
     
//       }

//       case 'twitch': {
//         const url = `https://www.twitch.tv/${data}`;
//         const bundlename: any = `https://www.twitch.tv/${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'indeed': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else if (this.platform.IOS) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else if (this.platform.SAFARI) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'quora': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var bundlename: any = `quora://${data}`;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var mydata = data
//           if (mydata.includes('https:')) {
//             var pathname = mydata.split('profile/')[1];
//           }
//           var url: any = `${data}`
//           var bundlename: any = "https://www.quora.com/profile/" + pathname;
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }


//       case 'dribbble': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else if (this.platform.IOS) {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'spotify': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var bundlename: any = `spotify://${data}`;
//         }

//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `${data}`
//           var mydata = data
//           if (mydata.includes('https:')) {
//             var pathname = mydata.split('spotify.com/')[1];
//           }
//           var bundlename: any = "https://open.spotify.com/" + pathname;
//         }

//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'soundcloud': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var bundlename: any = `soundcloud://${data}`;
//         }
//         else if (this.platform.IOS) {
//           var url: any = `${data}`
//           var bundlename: any = `soundcloud://${data}`;
//         }

//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'qzone': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'weibo': {
//         const url = `http://ir.weibo.com`
//         const bundlename: any = `http://ir.weibo.com`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'renren': {
//         const url = `http://www.renren.com`
//         const bundlename: any = `http://www.renren.com`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'vimeo': {
//         const url = `${data}`
//         const mydata = data.split("https://")[1];
//         const bundlename: any = `https://${mydata}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'link': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         localStorage.setItem(slug, JSON.stringify(data));
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'yelp': {
//         var a = data;
//         if (a.includes('https:')) {
//           var pathname = a.split('yelp.co.uk/')[1];
//         }
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = "https://www.yelp.co.uk/" + pathname;
//           var bundlename: any = "https://www.yelp.co.uk/" + pathname;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `${data}`;
//           var mydata = data;
//           if (mydata.includes('https:')) {
//             var pathname = mydata.split('yelp.co.uk/')[1];
//           }
//           var bundlename: any = "https://www.yelp.co.uk/" + pathname;
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'trustpilot': {
//         const url = `https://www.trustpilot.com`
//         const bundlename: any = `https://www.trustpilot.com`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'doctolib': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
        
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'lost_found': {
//         const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'emenu': {
//         const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`
//         const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'calendar': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'cv': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }


//       case 'event': {
//         const url = environment.export_event + data 
//         const bundlename: any = environment.export_event + data
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
//       case 'contact': {
//         const url: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'wechat': {


//         const url: any = `${data}`;
//         const bundlename: any = `${data}`;
        
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'telegram': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//             var url: any = `https://telegram.me/${data}`
//             var bundlename: any = `https://telegram.me/${data}`;
//           }
         
//           else if (this.platform.IOS) {
//             var url: any = `https://telegram.me/${data}`
//             var bundlename: any = `tg://msg?text=&to=@${data}`;
         
//         }
//         else {
//             var url: any = `https://telegram.me/${data}`
//             var bundlename: any = `https://telegram.me/${data}`;
//           }
        
        
//         let dataUrl = { url, bundlename };
//         return dataUrl
//       }

//       case 'linktree': {
//         const url: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
//       case 'emergency': {
//         const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
//         localStorage.setItem(slug, JSON.stringify(data));
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
      

//       case 'whatsApp': {
//         const url = `http://api.whatsapp.com/send?phone=${data}&text=${title}`
//         const bundlename: any = `http://api.whatsapp.com/send?phone=${data}&text=${title}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'email': {
//         const url: any = `mailto:${data}`;
//         const bundlename: any = `mailto:${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }


//       case 'messenger': {
//         if (`${data}`.includes("id")) {
//           let data1 = `${data}`
//           data1.split("=");
//           let splitdata = data1.split("=");
//           var url: any = 'https://www.messenger.com/t/' + splitdata[1];
//           var bundlename: any = 'https://www.messenger.com/t/' + splitdata[1];
//         }
//         else {
//           var data2 = `${data}`
//           var data3 = data2.split("https://www.facebook.com/")[1];
//           var url: any = 'https://www.messenger.com/t/' + data3;
//           var bundlename: any = 'https://www.messenger.com/t/' + data3;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'paypal': {
//         if (data.includes("https://www.paypal.me/")) {
//           var url: any = `${data}`;
//           var bundlename: any = `${data}`;
//         }
//         else {
//           var url: any = `https://www.paypal.me/${data}`;
//           var bundlename: any = `https://www.paypal.me/${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'music': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'notes': { 
//         const url: any = `${environment.redirectLinkBaseUrl}/notes/${data.base64_id}`;
//         const bundlename: any = `${environment.redirectLinkBaseUrl}/notes/${data.base64_id}`;
//         localStorage.setItem(slug, JSON.stringify(data));
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'waze': {


//         const url = `${data}`;
//         const bundlename: any = `${data}`;
       
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'tripAdvisor': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var mydata = data
//           if (mydata.includes('https:')) {
//             var pathname = mydata.split('tripadvisor.')[1];
//           }
//           var bundlename: any = "https://www.tripadvisor." + pathname;
//         }

//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `${data}`;
//           var mydata = data
//           if (mydata.includes('https:')) {
//             var bundlename: any = `${data}`;
//           }
//           else {
//             var bundlename: any = "https://www.tripadvisor.com/" + data;
//           }
//         }

//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }

//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'messages': {
//         if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `sms://${title}&body=${data}`
//           var bundlename: any = `sms://${title}&body=${data}`;
//         }
//         else {
//           var url: any = `sms:${title}&body=${data}`
//           var bundlename: any = `sms:${title}&body=${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }


//       case 'googleMaps': {
//         const url = `http://maps.google.co.in/maps?q=${data}`
//         const bundlename: any = ` http://maps.google.co.in/maps?q=${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'chrome': {
//         if (this.platform.ANDROID && this.platform.isBrowser) {
//           var url: any = `${data}`
//           var bundlename: any = `googlechrome://${data}`;
//         }
//         else if (this.platform.IOS && this.platform.SAFARI) {
//           var url: any = `${data}`
//           var mydata = data
//           if (mydata.includes('https:')) {
//             var pathname = mydata.split('https://')[1];
//             var bundlename: any = "googlechrome://" + pathname;
//           }
//           else {
//             var bundlename: any = "https://" + pathname;
//           }
//         }
//         else {
//           var url: any = `${data}`
//           var bundlename: any = `${data}`;
//         }
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'safari': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'lydia': {
//         const url = `https://lydia-app.com`
//         const bundlename: any = ` https://lydia-app.com`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'paylibb': {
//         const url = `https://www.paylib.fr/activer-paylib/banque-bcp`
//         const bundlename: any = `https://www.paylib.fr/activer-paylib/banque-bcp`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }


//       case 'maps': {
//         const url = `http://maps.apple.com/?address=${data}`;
//         const bundlename: any = `http://maps.apple.com/?address=${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

   

//       case 'uber': {

//         var url: any = `${data}`
//         var bundlename: any = `${data}`
//         let dataUrl = { url, bundlename };
//         return  dataUrl;
//       }

//       case 'mappy': {
//         const url = `https://fr.mappy.com/plan#/${data}`;
//         const bundlename: any = `https://fr.mappy.com/plan#/${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'googleMeet': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'botim': {
//         const url = `https://web.botim.me/#/${data}`
//         const bundlename: any = `https://botim.me/#/${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
//       case 'citymapper': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
//       case 'moovit': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'mappy': {
//         const url = `https://fr.mappy.com/plan#/${data}`
//         const bundlename: any = `https://fr.mappy.com/plan#/${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       // ***************************** New Links *****************************

//       case 'calendly': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'opensea': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//         case 'airbnb': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'patreon': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'onlyfans': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
      
//       case 'upwork': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'fiverr': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'eventbrite': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'github': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'freelancer': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

  

//       case 'linktree': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'booking': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'etsy': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
      
//       case 'ratp': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'amazon': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'embedvideo': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'applink': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'clubhouse': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'facetime': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'slack': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'discord': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'wise': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }
      
//       case 'revolut': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'freelancer': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'reviews': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'reservations': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'podcasts': {
//         const url = `${data}`
//         const bundlename: any = `${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

//       case 'customlink': {
//         const url =  data.includes('https') || data.includes('http') ? `${data}` : `https://${data}`
//         const bundlename: any =  data.includes('https') || data.includes('http') ? `${data}` : `https://${data}`;
//         let dataUrl = { url, bundlename };
//         return dataUrl;
//       }

// // ***************************** New Links *****************************


//       case 'phone': {
//         this.spinner.hide();
//         const url = `${data}`
//         document.location.href = "tel:+" + url
//       }
//     }
//   }
redirectToUrl(data: any, slug: string, title: string,dailcode?:any) {
  console.log(data);
  
  debugger
  switch (slug) {
    case 'instagram': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `https://www.instagram.com/${data}`;
        var bundlename: any = `instagram://user?username=${data}`;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `https://www.instagram.com/${data}`
        var bundlename: any = `https://www.instagram.com/${data}`;
      }
      else {

        var url: any = `https://www.instagram.com/${data}`
        var bundlename: any = `https://www.instagram.com/${data}`;
      }
      let dataUrl:any = { url, bundlename };
      return dataUrl;
    }
    case 'tiktok': {
      const url = `https://www.tiktok.com/@${data}`
      const bundlename: any = `https://www.tiktok.com/@${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    case 'snapchat': {
      const url = `https://www.snapchat.com/add/${data}`
      const bundlename: any = `https://www.snapchat.com/add/${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    case 'skype': {
      if (this.platform.isBrowser && this.platform.ANDROID) {
        var url: any = `https://www.skype.com/en`
        var bundlename: any = `skype:${data}?chat`;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `https://www.skype.com/en`
        var bundlename: any = `skype://`;
      }
      else {
        var url: any = `https://www.skype.com/en`
        var bundlename: any = `https://www.skype.com/en`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'meetup': {
      if (this.platform.isBrowser && this.platform.ANDROID) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    case 'behance': {
      var mydata = data;
      if (!mydata.includes('https://')) {
        var pathname = mydata.split('https://')[1];
        var url: any = "https://www.behance.net/" + pathname;
        var bundlename: any = "https://www.behance.net/" + pathname;
      }
      else {
        var url: any = mydata;
        var bundlename: any = mydata;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'facebook': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var a = data
        if (a.includes('id')) {
          var userid = a.split("id=")[1]
          console.log(userid)
          var url: any = `${data}`
          var bundlename: any = `fb://profile/${userid}`;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `fb://facewebmodal/f?href=${data}`;
        }
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var a = data
        if (a.includes('id')) {
          var userid = a.split("id=")[1]
          console.log(userid)
          var url: any = `${data}`
          var bundlename: any = `fb://profile/${userid}`;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `fb://facewebmodal/f?href=${data}`;
        }
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'twitter': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `https://twitter.com/${data}`;
        var bundlename: any = `twitter://user?screen_name=${data}`;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `https://twitter.com/${data}`;
        var bundlename: any = `https://twitter.com/${data}`;
      }
      else {
        var url: any = `https://twitter.com/${data}`
        var bundlename: any = `https://twitter.com/${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'youtube': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var mydata = data
        if (mydata.includes('watch?v=')) {
          var pathname = mydata.split('watch?v=')[1];
        }
        else {
          var pathname = mydata.split('youtu.be/')[1];
        }
        var url: any = `${data}`
        var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;
      }
      else if (this.platform.IOS) {
        var mydata = data
        if (mydata.includes('watch?v=')) {
          var pathname = mydata.split('watch?v=')[1];
        }
        else {
          var pathname = mydata.split('youtu.be/')[1];
        }
        var url: any = `${data}`
        var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;
      }
      else {
        var mydata = data
        if (mydata.includes('watch?v=')) {
          var pathname = mydata.split('watch?v=')[1];
        }
        else {
          var pathname = mydata.split('youtu.be/')[1];
        }
        var url: any = `${data}`
        var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'youtubeMusic': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'linkedin': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var mydata = data
        if (mydata.includes('https:')) {
          var pathname = mydata.split('linkedin.com/in/')[1];
          var bundlename: any = "https://www.linkedin.com/in/" + pathname;
        }
        else {
          var bundlename: any = "linkedin://" + pathname;
        }
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `${data}`
        var mydata = data
        if (mydata.includes('https:')) {
          var pathname = mydata.split('linkedin.com/in/')[1];
          var bundlename: any = "https://www.linkedin.com/in/" + pathname;
        }
        else {
          var bundlename: any = "linkedin://" + pathname;
        }
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }


    case 'devianArt': {
      debugger;
      if (this.platform.ANDROID && this.platform.isBrowser) {

        if(!(`${data}`).includes('https://')){
          var url: any = `https://www.deviantart.com/${data}`
          var bundlename: any = `DeviantArt://profile/${data}`; 

      }
      else{
        var url: any = `${data}`
        var bundlename: any = `DeviantArt://profile/${data}`;  
      }
     }
      else if (this.platform.IOS && this.platform.SAFARI) {
        if(!(`${data}`).includes('https://')){
          var url: any = `https://www.deviantart.com/${data}`
          var bundlename: any = `DeviantArt://profile/${data}`; 

      }
      else{
        var url: any = `${data}`
        var bundlename: any = `DeviantArt://profile/${data}`;  
      }
      }
      else {
        if(!(`${data}`).includes('https://')){
          var url: any = `https://www.deviantart.com/${data}`
          var bundlename: any = `https://www.deviantart.com/${data}`; 

      }
      else{
        var url: any = `${data}`
        var bundlename: any = `${data}`;  
      }
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'pinterest': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `https://www.pinterest.com/${data}`
        var bundlename: any = `https://www.pinterest.com/${data}`;
      }
      else if (this.platform.IOS) {
        var url: any = `https://www.pinterest.com/${data}`
        var bundlename: any = `https://www.pinterest.com/${data}`;
      }
      else {
        var url: any = `https://www.pinterest.com/${data}`
        var bundlename: any = `https://www.pinterest.com/${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'reddit': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var bundlename: any = `reddit://${data}`;
      }
      else if (this.platform.IOS) {
        var url: any = `${data}`
        var bundlename: any = `reddit://${data}`;
      }
      else {
        var url: any = `https://www.reddit.com/user/${data}`
        var bundlename: any = `https://www.reddit.com/user/${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'zoom': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'viber': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `viber://chat?number=${data}`
        var bundlename: any = `viber://chat?number=${data}`;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `https://account.viber.com/`;
        var bundlename: any = `viber://chat?number=${data}`;
      }
      else {
        var url: any = `https://account.viber.com/contact?number=${data}`
        var bundlename: any = `https://account.viber.com/contact?number=${data}`
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'microsoftTeams': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var mydata = data
        if (mydata.includes('https:')) {
          var teampath = mydata.split('meet/')[1];
        }
        var url: any = `${data}`
        var bundlename: any = "https://teams.live.com/meet/" + teampath;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var mydata = data
        if (mydata.includes('https:')) {
          var pathname = mydata.split('meet/')[1];
        }
        var url: any = `${data}`
        var bundlename: any = "https://teams.live.com/meet/" + pathname;
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'drive': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var mydata = data
        if (mydata.includes('https')) {
          var pathname = mydata.split('google.com/')[1];
        }
        var url: any = `${data}`
        var bundlename: any = " https://drive.google.com/" + pathname;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var mydata = data
        if (mydata.includes('https')) {
          var pathname = mydata.split('google.com/')[1];
        }
        var url: any = `${data}`
        var bundlename: any = " https://drive.google.com/" + pathname;
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl

    }

    case 'qq': {

      const url = `${data}`
      const bundlename: any = `${data}`;

      // const url = `${environment.redirectLinkBaseUrl}/slug-profile/${data}`
      // const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${data}`;

      // const url = `${environment.redirectLinkBaseUrl}/slug-profile/${data}`
      // const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'kik': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        if(!(`${data}`).includes('https')){
          var url: any = `https://kik.me/${data}`
          var bundlename: any = `https://kik.me/${data}`;
        }
        else{
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
      
      }
      else if (this.platform.IOS) {
        if(!(`${data}`).includes('https')){
          var url: any = `https://kik.me/${data}`
          var bundlename: any = `https://kik.me/${data}`;
        }
        else{
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
      }
      else {
        if(!(`${data}`).includes('https')){
          var url: any = `https://kik.me/${data}`
          var bundlename: any = `https://kik.me/${data}`;
        }
        else{
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
      }
      let dataUrl = { url, bundlename };
      return dataUrl
    }

    case 'tumblr': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var mydata = data
        if (mydata.includes('https')) {
          var pathname = mydata.split('https://')[1];
        }
        else {
          var pathname = mydata.split('http://')[1];
        }
        var pathname1 = pathname.split('.tumblr.com')[0];
        var url: any = `${data}`
        var bundlename: any = "https://" + pathname1 + ".tumblr.com";
      }

      else if (this.platform.IOS) {
        var mydata = data
        if (mydata.includes('https')) {
          var pathname = mydata.split('https://')[1];
        }
        else {
          var pathname = mydata.split('http://')[1];

        }
        var pathname1 = pathname.split('.tumblr.com')[0];
        var url: any = `${data}`
        var bundlename: any = "tumblr://x-callback-url/blog?blogName=" + pathname1;
      }

      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'tinder': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else if (this.platform.IOS) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else if (this.platform.SAFARI) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
      // if (this.platform.ANDROID && this.platform.isBrowser) {
      //     if(!(`${data}`).includes('https://')){
      //       var url: any = `https://tinder.com/@${data}`
      //       var bundlename: any = `tinder://${data}`; 

      //   }
      //   else{
      //     var url: any = `${data}`
      //     var bundlename: any = `tinder://${data}`;  
      //   }
      //  }
      // else if (this.platform.IOS) {
      //   if(!(`${data}`).includes('https://')){
      //     var url: any = `https://tinder.com/@${data}`
      //     var bundlename: any = `tinder://${data}`; 

      // }
      // else{
      //   var url: any = `${data}`
      //   var bundlename: any = `tinder://${data}`;  
      // }
      // }
      // else {
      //   if(!(`${data}`).includes('https://')){
      //     var url: any = `https://tinder.com/@${data}`
      //     var bundlename: any = `tinder://${data}`; 

      // }
      // else{
      //   var url: any = `${data}`
      //   var bundlename: any = `${data}`;  
      // }
      // }
      // let dataUrl = { url, bundlename };
      // return dataUrl;
    }

    case 'twitch': {
      const url = `https://www.twitch.tv/${data}`;
      const bundlename: any = `https://www.twitch.tv/${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'indeed': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else if (this.platform.IOS) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else if (this.platform.SAFARI) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'quora': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var bundlename: any = `quora://${data}`;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var mydata = data
        if (mydata.includes('https:')) {
          var pathname = mydata.split('profile/')[1];
        }
        var url: any = `${data}`
        var bundlename: any = "https://www.quora.com/profile/" + pathname;
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }


    case 'dribbble': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else if (this.platform.IOS) {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'spotify': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var bundlename: any = `spotify://${data}`;
      }

      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `${data}`
        var mydata = data
        if (mydata.includes('https:')) {
          var pathname = mydata.split('spotify.com/')[1];
        }
        var bundlename: any = "https://open.spotify.com/" + pathname;
      }

      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'soundcloud': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var bundlename: any = `soundcloud://${data}`;
      }
      else if (this.platform.IOS) {
        var url: any = `${data}`
        var bundlename: any = `soundcloud://${data}`;
      }

      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'qzone': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'weibo': {
      const url = `http://ir.weibo.com`
      const bundlename: any = `http://ir.weibo.com`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'renren': {
      const url = `http://www.renren.com`
      const bundlename: any = `http://www.renren.com`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'vimeo': {
      const url = `${data}`
      const mydata = data.split("https://")[1];
      const bundlename: any = `https://${mydata}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'link': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      localStorage.setItem(slug, JSON.stringify(data));
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'yelp': {
      var a = data;
      if (a.includes('https:')) {
        var pathname = a.split('yelp.co.uk/')[1];
      }
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = "https://www.yelp.co.uk/" + pathname;
        var bundlename: any = "https://www.yelp.co.uk/" + pathname;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `${data}`;
        var mydata = data;
        if (mydata.includes('https:')) {
          var pathname = mydata.split('yelp.co.uk/')[1];
        }
        var bundlename: any = "https://www.yelp.co.uk/" + pathname;
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'trustpilot': {
      const url = `https://www.trustpilot.com`
      const bundlename: any = `https://www.trustpilot.com`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'doctolib': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'lost_found': {
      const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
      const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
     // localStorage.setItem(slug, JSON.stringify(data));
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'emenu': {
      const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`
      const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
      //localStorage.setItem(slug, JSON.stringify(data));
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'calendar': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'cv': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }


    case 'event': {
      const url = environment.export_event + data //`https://saurabh.parastechnologies.in/BInTouch/api/export_event/?event_id=${data}`
      const bundlename: any = environment.export_event + data//`https://saurabh.parastechnologies.in/BInTouch/api/export_event/?event_id=${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    case 'contact': {
      const url: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
      const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
     // localStorage.setItem(slug, JSON.stringify(data));
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'wechat': {
      // const url: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}`;
      // const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}`;
      // localStorage.setItem(slug, JSON.stringify(data));

      const url: any = `${data}`;
      const bundlename: any = `${data}`;
      
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    // case 'telegram': {
    //   if (this.platform.ANDROID && this.platform.isBrowser) {
    //     if(!(`${data}`).includes('https')){
    //       var url: any = `https://telegram.me/${data}`
    //       var bundlename: any = `https://telegram.me/${data}`;
    //     }
    //     else{
    //       var url: any = `https://telegram.me/${data}`
    //       var bundlename: any = `https://telegram.me/${data}`;
    //     }
      
    //   }
    //   else if (this.platform.IOS) {
    //     if(!(`${data}`).includes('https')){
    //       var url: any = `https://telegram.me/${data}`
    //       var bundlename: any = `tg://msg?text=&to=@${data}`;
    //     }
    //     else{
    //       var url: any = `https://telegram.me/${data}`
    //       var bundlename: any = `https://telegram.me/${data}`;
    //     }
    //   }
    //   else {
    //     if(!(`${data}`).includes('https')){
    //       var url: any = `https://telegram.me/${data}`
    //       var bundlename: any = `https://telegram.me/${data}`;
    //     }
    //     else{
    //       var url: any = `https://telegram.me/${data}`
    //       var bundlename: any = `https://telegram.me/${data}`;
    //     }
    //   }
    //   let dataUrl = { url, bundlename };
    //   return dataUrl
    // }

    case 'telegram': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `https://telegram.me/${data}`
          var bundlename: any = `https://telegram.me/${data}`;
        }
       
        else if (this.platform.IOS) {
          var url: any = `https://telegram.me/${data}`
          var bundlename: any = `tg://msg?text=&to=@${data}`;
       
      }
      else {
          var url: any = `https://telegram.me/${data}`
          var bundlename: any = `https://telegram.me/${data}`;
        }
      
      
      let dataUrl = { url, bundlename };
      return dataUrl
    }

    case 'linktree': {
      const url: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
      const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
      //localStorage.setItem(slug, JSON.stringify(data));
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    case 'emergency': {
      const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
      const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
      localStorage.setItem(slug, JSON.stringify(data));
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    // case 'file1': {
    //   const url = `https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${data.content}`;
    //   const bundlename: any = `https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${data.content}`;
    //   localStorage.setItem(slug, JSON.stringify(data));
    //   let dataUrl = { url, bundlename };
    //   return dataUrl;
    // }
    // case 'file2': {
    //   const url = `https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${data.content}`;
    //   const bundlename: any = `https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${data.content}`;
    //   localStorage.setItem(slug, JSON.stringify(data));
    //   let dataUrl = { url, bundlename };
    //   return dataUrl;
    // }
    // case 'file3': {
    //   const url = `https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${data.content}`;
    //   const bundlename: any = `https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${data.content}`;
    //   localStorage.setItem(slug, JSON.stringify(data));
    //   let dataUrl = { url, bundlename };
    //   return dataUrl;
    // }

    case 'whatsApp': {
      // const url = `http://api.whatsapp.com/send?phone=${data}&text=${title}`
      // const bundlename: any = `http://api.whatsapp.com/send?phone=${data}&text=${title}`;
      // let dataUrl = { url, bundlename };
      // return dataUrl;
    //  add this code because above code is not working 
    debugger
    // const sanitizedDialCode = dailcode.startsWith('+') ? dailcode.slice(1) : dailcode;
    // const url = `http://api.whatsapp.com/send?phone=${sanitizedDialCode}${data}&text=${title}`;
    // const bundlename = `http://api.whatsapp.com/send?phone=${sanitizedDialCode}${data}&text=${title}`;
    const sanitizedDialCode = dailcode?.startsWith('+') ? dailcode.slice(1) : dailcode;
const sanitizedData = data.replace(/\D/g, '');
// .replace(/\D/g, '');
const url = `http://api.whatsapp.com/send?phone=${sanitizedDialCode}${sanitizedData}&text=${title}`;
const bundlename = `http://api.whatsapp.com/send?phone=${sanitizedDialCode}${sanitizedData}&text=${title}`;
    let dataUrl = { url, bundlename };
    return dataUrl


      
    }

    case 'email': {
      const url: any = `mailto:${data}`;
      const bundlename: any = `mailto:${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }


    case 'messenger': {
      if (`${data}`.includes("id")) {
        let data1 = `${data}`
        data1.split("=");
        let splitdata = data1.split("=");
        var url: any = 'https://www.messenger.com/t/' + splitdata[1];
        var bundlename: any = 'https://www.messenger.com/t/' + splitdata[1];
      }
      else {
        var data2 = `${data}`
        var data3 = data2.split("https://www.facebook.com/")[1];
        var url: any = 'https://www.messenger.com/t/' + data3;
        var bundlename: any = 'https://www.messenger.com/t/' + data3;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'paypal': {
      if (data.includes("https://www.paypal.me/")) {
        var url: any = `${data}`;
        var bundlename: any = `${data}`;
      }
      else {
        var url: any = `https://www.paypal.me/${data}`;
        var bundlename: any = `https://www.paypal.me/${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'music': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'notes': { 
      console.log(data)
      const url: any = `${environment.redirectLinkBaseUrl}/notes/${data.base64_id}`;
      const bundlename: any = `${environment.redirectLinkBaseUrl}/notes/${data.base64_id}`;
      localStorage.setItem(slug, JSON.stringify(data));
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'waze': {
       // const mydata = data.split("https")[1]
      // this.wazeData = "https" + mydata
      // const url = `${this.wazeData}`;
      // const bundlename: any = `${this.wazeData}`;

      const url = `${data}`;
      const bundlename: any = `${data}`;
     
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'tripAdvisor': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var mydata = data
        if (mydata.includes('https:')) {
          var pathname = mydata.split('tripadvisor.')[1];
        }
        var bundlename: any = "https://www.tripadvisor." + pathname;
      }

      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `${data}`;
        var mydata = data
        if (mydata.includes('https:')) {
          var bundlename: any = `${data}`;
        }
        else {
          var bundlename: any = "https://www.tripadvisor.com/" + data;
        }
      }

      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }

      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'messages': {
      if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `sms://${title}&body=${data}`
        var bundlename: any = `sms://${title}&body=${data}`;
      }
      else {
        var url: any = `sms:${title}&body=${data}`
        var bundlename: any = `sms:${title}&body=${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }


    case 'googleMaps': {
      const url = `http://maps.google.co.in/maps?q=${data}`
      const bundlename: any = ` http://maps.google.co.in/maps?q=${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'chrome': {
      if (this.platform.ANDROID && this.platform.isBrowser) {
        var url: any = `${data}`
        var bundlename: any = `googlechrome://${data}`;
      }
      else if (this.platform.IOS && this.platform.SAFARI) {
        var url: any = `${data}`
        var mydata = data
        if (mydata.includes('https:')) {
          var pathname = mydata.split('https://')[1];
          var bundlename: any = "googlechrome://" + pathname;
        }
        else {
          var bundlename: any = "https://" + pathname;
        }
      }
      else {
        var url: any = `${data}`
        var bundlename: any = `${data}`;
      }
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'safari': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'lydia': {
      const url = `https://lydia-app.com`
      const bundlename: any = ` https://lydia-app.com`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'paylibb': {
      const url = `https://www.paylib.fr/activer-paylib/banque-bcp`
      const bundlename: any = `https://www.paylib.fr/activer-paylib/banque-bcp`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }


    case 'maps': {
      const url = `http://maps.apple.com/?address=${data}`;
      const bundlename: any = `http://maps.apple.com/?address=${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    // case 'uber': {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((position => {
    //       var currLat = position.coords.latitude;
    //       var currLng = position.coords.longitude;
    //       this.current_location_latitude = currLat
    //       this.current_location_longitude = currLng
    //       var geocoder = new google.maps.Geocoder();
    //       var latlng = new google.maps.LatLng(currLat, currLng);
    //       geocoder.geocode({ 'latLng': latlng }, (results: any) => {
    //         this.current_address = results[0].formatted_address;
    //       });
    //     }));
    //     var geocoder = new google.maps.Geocoder();
    //     var destination_address = `${data}`;
    //     this.destination_addressvar = destination_address
    //     geocoder.geocode({ 'address': destination_address }, (results: any, status: any) => {
    //       console.log(results)
    //       if (status == google.maps.GeocoderStatus.OK) {
    //         this.destination_latitude = results[0].geometry.location.lat();;
    //         this.destination_longitude = results[0].geometry.location.lng();
    //         console.log(this.destination_latitude, this.destination_longitude)
    //       }
    //     });
    //   }
    //   if (this.platform.ANDROID && this.platform.isBrowser) {
    //     var url: any = "https://m.uber.com/looking?drop[0]={id:ChIJ3bF9wdLtDzkRrCELk-NAhRQ, addressLine1 :" + this.destination_addressvar + ",provider : google_places ,locale:en ,latitude:" + this.destination_latitude + ",longitude:" + this.destination_longitude + "}&pickup={id:ChIJb4qTRgaDGjkR_DQ_657zZQ0,addressLine1:" + this.current_address + ",provider:google_places,locale:en-GB,latitude:" + this.current_location_latitude + ",longitude:" + this.current_location_longitude + "}&vehicle=a4770b75-bd1d-4c4f-8315-afef635b8b28"

    //     var bundlename: any = "uber://?client_id=XThZpPIzIIne69KIjpnF_Tg9mpdoakmw&action=setPickup&pickup[latitude]=" + this.current_location_latitude + "&pickup[longitude]=" + this.current_location_longitude + "&pickup[nickname]=" + this.current_address + "&pickup[formatted_address]=" + this.current_address + "&dropoff[latitude]=" + this.destination_latitude + "&dropoff[longitude]=" + this.destination_longitude + "&dropoff[nickname]=" + this.destination_addressvar + "&dropoff[formatted_address]=" + this.destination_addressvar + "&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d";
    //   }
    //   else if (this.platform.IOS) {
    //     var url: any = "https://m.uber.com/looking?drop[0]={id:ChIJ3bF9wdLtDzkRrCELk-NAhRQ, addressLine1 :" + this.destination_addressvar + ",provider : google_places ,locale:en ,latitude:" + this.destination_latitude + ",longitude:" + this.destination_longitude + "}&pickup={id:ChIJb4qTRgaDGjkR_DQ_657zZQ0,addressLine1:" + this.current_address + ",provider:google_places,locale:en-GB,latitude:" + this.current_location_latitude + ",longitude:" + this.current_location_longitude + "}&vehicle=a4770b75-bd1d-4c4f-8315-afef635b8b28"
    //     var bundlename: any = "uber://?client_id=XThZpPIzIIne69KIjpnF_Tg9mpdoakmw&action=setPickup&pickup[latitude]=" + this.current_location_latitude + "&pickup[longitude]=" + this.current_location_longitude + "&pickup[nickname]=" + this.current_address + "&pickup[formatted_address]=" + this.current_address + "&dropoff[latitude]=" + this.destination_latitude + "&dropoff[longitude]=" + this.destination_longitude + "&dropoff[nickname]=" + this.destination_addressvar + "&dropoff[formatted_address]=" + this.destination_addressvar + "&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d";

    //   }
    //   else {
    //     var url: any = "https://m.uber.com/ul?client_id=XThZpPIzIIne69KIjpnF_Tg9mpdoakmw&action=setPickup&pickup[latitude]=" + this.current_location_latitude + "&pickup[longitude]=" + this.current_location_longitude + "&pickup[nickname]=" + this.current_address + "&pickup[formatted_address]=" + this.current_address + "&dropoff[latitude]=" + this.destination_latitude + "&dropoff[longitude]=" + this.destination_latitude + "&dropoff[nickname]=" + this.destination_addressvar + "&dropoff[formatted_address]=" + this.destination_addressvar + "&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d";
    //     var bundlename: any = "https://m.uber.com/ul?client_id=XThZpPIzIIne69KIjpnF_Tg9mpdoakmw&action=setPickup&pickup[latitude]=" + this.current_location_latitude + "&pickup[longitude]=" + this.current_location_longitude + "&pickup[nickname]=" + this.current_address + "&pickup[formatted_address]=" + this.current_address + "&dropoff[latitude]=" + this.destination_latitude + "&dropoff[longitude]=" + this.destination_latitude + "&dropoff[nickname]=" + this.destination_addressvar + "&dropoff[formatted_address]=" + this.destination_addressvar + "&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d";
    //   }
    //   let dataUrl = { url, bundlename };
    //   return dataUrl;
    // }

    case 'uber': {

      var url: any = `${data}`
      var bundlename: any = `${data}`



      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition((position => {
      //     var currLat = position.coords.latitude;
      //     var currLng = position.coords.longitude;
      //     this.current_location_latitude = currLat
      //     this.current_location_longitude = currLng
      //     var geocoder = new google.maps.Geocoder();
      //     var latlng = new google.maps.LatLng(currLat, currLng);
      //     geocoder.geocode({ 'latLng': latlng }, (results: any) => {
      //       this.current_address = results[0].formatted_address;
      //       console.log(this.current_address)
      //     });
      //   }));
      //   var geocoder = new google.maps.Geocoder();
      //   var destination_address = `${data}`;
      //   this.destination_addressvar = destination_address
      //   geocoder.geocode({ 'address': destination_address }, (results: any, status: any) => {
      //     console.log(results)
      //     if (status == google.maps.GeocoderStatus.OK) {
      //       this.destination_latitude = results[0].geometry.location.lat();;
      //       this.destination_longitude = results[0].geometry.location.lng();
      //       console.log(this.destination_latitude, this.destination_longitude)
      //     }
      //   });
      // }

      // if (this.platform.ANDROID && this.platform.isBrowser) {
      //   var url: any = "https://m.uber.com/looking?drop[0]={id:ChIJ3bF9wdLtDzkRrCELk-NAhRQ, addressLine1 :" +  this.destination_addressvar + ",provider : google_places ,locale:en ,latitude:" + this.destination_latitude + ",longitude:" + this.destination_longitude + "}&pickup={id:ChIJb4qTRgaDGjkR_DQ_657zZQ0,addressLine1:" + this.current_address + ",provider:google_places,locale:en-GB,latitude:" + this.current_location_latitude + ",longitude:" + this.current_location_longitude + "}&vehicle=a4770b75-bd1d-4c4f-8315-afef635b8b28"
      //   var bundlename: any = "uber://?client_id=XThZpPIzIIne69KIjpnF_Tg9mpdoakmw&action=setPickup&pickup[latitude]=" + this.current_location_latitude + "&pickup[longitude]=" + this.current_location_longitude + "&pickup[nickname]=" + this.current_address + "&pickup[formatted_address]=" + this.current_address + "&dropoff[latitude]=" + this.destination_latitude + "&dropoff[longitude]=" + this.destination_longitude + "&dropoff[nickname]=" + this.destination_addressvar + "&dropoff[formatted_address]=" + this.destination_addressvar + "&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d";
      // }
      // else if (this.platform.IOS) {
      //   var url: any = "https://m.uber.com/looking?drop[0]={id:ChIJ3bF9wdLtDzkRrCELk-NAhRQ, addressLine1 :" + this.destination_addressvar + ",provider : google_places ,locale:en ,latitude:" + this.destination_latitude + ",longitude:" + this.destination_longitude + "}&pickup={id:ChIJb4qTRgaDGjkR_DQ_657zZQ0,addressLine1:" + this.current_address + ",provider:google_places,locale:en-GB,latitude:" + this.current_location_latitude + ",longitude:" + this.current_location_longitude + "}&vehicle=a4770b75-bd1d-4c4f-8315-afef635b8b28"
      //   var bundlename:any = `uber://?client_id=XThZpPIzIIne69KIjpnF_Tg9mpdoakmw&action=setPickup&pickup[latitude]=${this.current_location_latitude}&pickup[longitude]=${this.current_location_longitude}&pickup[nickname]=UberHQ&pickup[formatted_address]=${this.current_address}&dropoff[latitude]=${this.destination_latitude}&dropoff[longitude]=${this.destination_longitude}&dropoff[nickname]=dest&dropoff[formatted_address]=${data}&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d&link_text=View%20team%20roster&partner_deeplink=partner%3A%2F%2Fteam%2F9383"
      //   // var bundlename: any = "uber://?client_id=XThZpPIzIIne69KIjpnF_Tg9mpdoakmw&action=setPickup&pickup[latitude]=" + this.current_location_latitude + "&pickup[longitude]=" + this.current_location_longitude + "&pickup[nickname]=" + this.current_address + "&pickup[formatted_address]=" + this.current_address + "&dropoff[latitude]=" + this.destination_latitude + "&dropoff[longitude]=" + this.destination_longitude + "&dropoff[nickname]=" + this.destination_addressvar + "&dropoff[formatted_address]=" + this.destination_addressvar + "&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d`;

      // }
      // else {
      //   var url:any = `https://m.uber.com/looking?drop[0]={"latitude":30.7017355,"longitude":76.7247759,"addressLine1":"Mohali 7 Phase","addressLine2":"Sector 61, Sahibzada Ajit Singh Nagar","id":"ChIJSXLL3pruDzkR7Jq0J0Io1V0","provider":"google_places","index":0}&pickup={"latitude":30.7023839,"longitude":76.72443729999999,"addressLine1":"Sant Isher Singh Public School","addressLine2":"2020, Mohali Stadium Rd, Sector 61, Sahibzada Ajit Singh Nagar, Punjab","id":"ChIJmaOD0ZruDzkRqHywR85p9l8","provider":"google_places","index":0}&vehicle=2021`
      //   var bundlename:any = `https://m.uber.com/looking?drop[0]={"latitude":30.7017355,"longitude":76.7247759,"addressLine1":"Mohali 7 Phase","addressLine2":"Sector 61, Sahibzada Ajit Singh Nagar","id":"ChIJSXLL3pruDzkR7Jq0J0Io1V0","provider":"google_places","index":0}&pickup={"latitude":30.7023839,"longitude":76.72443729999999,"addressLine1":"Sant Isher Singh Public School","addressLine2":"2020, Mohali Stadium Rd, Sector 61, Sahibzada Ajit Singh Nagar, Punjab","id":"ChIJmaOD0ZruDzkRqHywR85p9l8","provider":"google_places","index":0}&vehicle=2021` 
      //   // var url: any = `https://m.uber.com/ul/?action=setPickup&client_id=ChIJ3bF9wdLtDzkRrCELk-NAhRQ&pickup=my_location&dropoff[formatted_address]=Mohali%2C%20Punjab%2C%20India&dropoff[latitude]=30.704649&dropoff[longitude]=76.717873`;
      //   // var bundlename: any = `https://m.uber.com/ul/?action=setPickup&client_id=ChIJ3bF9wdLtDzkRrCELk-NAhRQ&pickup=my_location&dropoff[formatted_address]=Mohali%2C%20Punjab%2C%20India&dropoff[latitude]=30.704649&dropoff[longitude]=76.717873`;
      // }
      let dataUrl = { url, bundlename };
      return  dataUrl;
    }

    case 'mappy': {
      const url = `https://fr.mappy.com/plan#/${data}`;
      const bundlename: any = `https://fr.mappy.com/plan#/${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'googleMeet': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'botim': {
      const url = `https://web.botim.me/#/${data}`
      const bundlename: any = `https://botim.me/#/${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    case 'citymapper': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    case 'moovit': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'mappy': {
      const url = `https://fr.mappy.com/plan#/${data}`
      const bundlename: any = `https://fr.mappy.com/plan#/${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    // ***************************** New Links *****************************

    case 'calendly': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'opensea': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

      case 'airbnb': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'patreon': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'onlyfans': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    
    case 'upwork': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'fiverr': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'eventbrite': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'github': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'freelancer': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }



    case 'linktree': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'booking': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'etsy': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    
    case 'ratp': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'amazon': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'embedvideo': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'applink': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'clubhouse': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'facetime': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'slack': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'discord': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'wise': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }
    
    case 'revolut': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'freelancer': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'reviews': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'reservations': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    case 'podcasts': {
      const url = `${data}`
      const bundlename: any = `${data}`;
      let dataUrl = { url, bundlename };
      return dataUrl;
    }

    // case 'bpay': {
    //   const url = `${data}`
    //   const bundlename: any = `${data}`;
    //   let dataUrl = { url, bundlename };
    //   return dataUrl;
    // }




// ***************************** New Links *****************************


    case 'phone': {
      const sanitizedDialCode = dailcode.startsWith('+') ? dailcode.slice(1) : dailcode;
      const sanitizedData = data.replace(/\s+/g, ''); 
      const d = `${sanitizedDialCode}${sanitizedData}`;
      // debugger
      this.spinner.hide();
      const url = `${d}`
      console.log(url)
      document.location.href = "tel:+" + url
      // this.spinner.hide();
      // const url = `${data}`
      // console.log(url)
      // document.location.href = "tel:+" + url
    
    }
  }
}

  showSpinner(): void {
    this.spinner.show();
  }
  hideSpinner(): void {
    this.spinner.hide();
  }

  ref(): void {
  }
}


