import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MessagingService } from './services/message/messaging.service';
import * as AOS from 'aos';
import { EventsService } from './services/event/events.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers:[EventsService]
})
export class AppComponent {
  checked: any;
  eventService: any;
  constructor(public event:EventsService, public router:Router,private route:ActivatedRoute,private messagingService:MessagingService){
    this.getNotification();

  }
  title = 'B-in-Touch';
  getNotification(){
    const userId = 'user00123';
  
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()

   }
   ngOnInit() {
    AOS.init();
    
    this.eventService =  this.event.fooSubject.subscribe((res: any) => {        // for light dark mode 
      
      if (res == 'light') {     // light mode
        var a:any = document.getElementById("wrapper"); 
        a.classList.remove('light-theme');
        a.className += " light-theme";
      }else{              // dark mode
        var a:any = document.getElementById("wrapper");  
       a.classList.remove('light-theme');
      }

    })
      
  }

  ngOnDestroy() {
    this.eventService.unsubscribe();
  }
}
