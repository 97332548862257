import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class LanguageInterceptor {

  constructor(
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    return next.handle(clonedReq);
  }
  handleRequest(req: HttpRequest<any>) {
    const selectedLang =sessionStorage.getItem('selectedLang');

    let authReq;
    authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Language': selectedLang ? selectedLang : 'english'

      })
    });
    if ((req.method.toLowerCase() == 'post' || req.method.toLowerCase() == 'put') && req.body instanceof FormData) {

      authReq = req.clone({
        headers: new HttpHeaders({
        'Language': selectedLang ? selectedLang : 'english'

        })
      });
    }
    return authReq;
  }
}
